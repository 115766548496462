import React from 'react';
import cx from 'classnames';
import {Navigation} from '../../Navigation/Navigation';
import {Cell} from '../Cell/Cell';
import {LayoutComponentProps} from '../../../types/app-types';
import s from './SimpleLayout.scss';
import {BottomPlaceholder} from '../../Placeholders/BottomPlaceholder';
import {ReviewsSlot} from '../../ReviewsSlot/ReviewsSlot';
import {ProductPageTopSlot} from '../../ProductPageTopSlot/ProductPageTopSlot';
import {SimpleLayoutHeader} from './SimpleLayoutHeader';
import {SimpleLayoutLeftSection} from './SimpleLayoutLeftSection';
import {SimpleLayoutRightSection} from './SimpleLayoutRightSection';

export const SimpleLayout: React.FunctionComponent<LayoutComponentProps> = ({settings, globals}) => {
  const {addReviewsSlotsToProductPage} = globals.experiments ?? {};

  return (
    <article className={s.container}>
      <ProductPageTopSlot />
      <Cell className={s.width}>{settings.shouldShowNavigation && <Navigation className={s.navigation} />}</Cell>
      <SimpleLayoutHeader settings={settings} globals={globals} />
      <div className={cx(s.width, s.main)}>
        <SimpleLayoutLeftSection globals={globals} settings={settings} />
        <SimpleLayoutRightSection globals={globals} settings={settings} />
      </div>
      <Cell>
        {addReviewsSlotsToProductPage && <ReviewsSlot />}
        <BottomPlaceholder />
      </Cell>
    </article>
  );
};
