import React from 'react';
import {IPageLink} from '@wix/wixstores-client-core/dist/es/src/types/wix-sdk';
import {IPropsInjectedByViewerScript} from '../../../types/app-types';
import s from './Breadcrumbs.scss';
import {useIsAfterHydration} from '../hooks/useIsAfterHydration';

export interface BreadcrumbsProps {
  path: Breadcrumb[];
  useHref: boolean;
  navigateToLink: IPropsInjectedByViewerScript['navigateToLink'];
  shouldRenderAfterHydration: boolean;
}

export const enum DataHooks {
  Breadcrumbs = 'breadcrumbs',
}

export interface Breadcrumb {
  name: string;
  url: string;
  relativeUrl?: string;
  sdkLink: IPageLink;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  path,
  useHref,
  navigateToLink,
  shouldRenderAfterHydration,
}: BreadcrumbsProps) => {
  const {isAfterHydration} = useIsAfterHydration();
  const shouldRenderBreadcrumbs = shouldRenderAfterHydration
    ? /* istanbul ignore next: hard to test */ isAfterHydration
    : true;

  return (
    <div data-hook={DataHooks.Breadcrumbs} className={s.breadcrumbs}>
      {shouldRenderBreadcrumbs &&
        path.map((link, i) => (
          <React.Fragment key={`crumb-${link.name}-${link.url}`}>
            {i > 0 ? ' / ' : ''}
            <a
              href={useHref ? link.url : null}
              onClick={/* istanbul ignore next */ () => link.url && navigateToLink(link)}
              aria-disabled={!link.url}>
              {link.name}
            </a>
          </React.Fragment>
        ))}
    </div>
  );
};
