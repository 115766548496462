import React from 'react';
import {Navigation} from '../../Navigation/Navigation';
import {ProductDescription} from '../../ProductDescription/ProductDescription';
import {InfoSectionLayout} from '../../InfoSectionLayout/InfoSectionLayout';
import {Cell} from '../Cell/Cell';
import {LayoutComponentProps} from '../../../types/app-types';
import s from './StunningLayout.scss';
import {BottomPlaceholder} from '../../Placeholders/BottomPlaceholder';
import {ReviewsSlot} from '../../ReviewsSlot/ReviewsSlot';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';
import {ProductPageSlotIds} from '../../../constants';
import {ProductPageTopSlot} from '../../ProductPageTopSlot/ProductPageTopSlot';
import {StunningLayoutHeader} from './StunningLayoutHeader';

export const StunningLayout: React.FunctionComponent<LayoutComponentProps> = ({settings, globals}) => {
  const {product} = globals;
  const {addReviewsSlotsToProductPage, renderProductPageSlots} = globals.experiments ?? {};

  return (
    <article className={s.container}>
      <ProductPageTopSlot />
      {settings.shouldShowNavigation && <Navigation className={s.navigation} />}
      <StunningLayoutHeader globals={globals} settings={settings} />
      <section className={s.containerDescription}>
        <Cell className={s.description}>
          <ProductDescription description={product.description} />
        </Cell>
      </section>
      {renderProductPageSlots && (
        <Cell>
          <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails4} />
        </Cell>
      )}
      {renderProductPageSlots && (
        <Cell className={s.productPageSlotBigSpacing}>
          <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails8} />
        </Cell>
      )}
      <section className={s.containerInfoSection}>
        {settings.shouldShowInfoSection && (
          <Cell className={s.info}>
            <InfoSectionLayout />
          </Cell>
        )}
      </section>
      {renderProductPageSlots && (
        <Cell className={s.productPageSlotBottomSpacing}>
          <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails9} />
        </Cell>
      )}
      <Cell>
        {addReviewsSlotsToProductPage && <ReviewsSlot />}
        <BottomPlaceholder />
      </Cell>
    </article>
  );
};
