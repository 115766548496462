import React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import s from './ProductDescription.scss';
import {defaultFontSize} from '../../../constants';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {SanitizeInnerHTMLWrapper} from '@wix/wixstores-client-common-components/dist/es/src/SanitizeInnerHTMLWrapper';
import stylesParams from '../../../../components/ProductPage/stylesParams';

export enum DataHook {
  DescriptionWrapper = 'description-wrapper',
  ContentWrapper = 'content-wrapper',
  Description = 'description',
  ShowMoreOrLess = 'show-more-or-less',
}

interface ProductDescriptionState {
  lineHeight: number;
  height: number;
  isCollapsedStatus: boolean;
}

export interface ProductDescriptionProps extends ProvidedGlobalProps, IProvidedTranslationProps {
  description: string;
}

class ProductDescriptionComponent extends React.Component<ProductDescriptionProps, ProductDescriptionState> {
  private readonly ref: React.RefObject<HTMLPreElement & HTMLDivElement>;

  constructor(props: ProductDescriptionProps) {
    super(props);
    const descriptionFontStyle = props.globals.styles.get(stylesParams.productPage_descriptionFontStyle);

    const fontSize = (descriptionFontStyle && descriptionFontStyle.size) || defaultFontSize;
    this.state = {
      isCollapsedStatus: true,
      height: 0,
      lineHeight: Math.ceil(fontSize * 1.5),
    };
    this.ref = React.createRef();
  }

  public componentDidMount(): void {
    this.setHeights();
  }

  private readonly setHeights = () => {
    const {description} = this.props;
    if (description) {
      this.setState({
        height: this.ref.current?.clientHeight,
      });
    }
  };

  public componentDidUpdate(): void {
    this.props.globals.updateLayout && this.props.globals.updateLayout();
  }

  private readonly isShowMoreOrLess = (): boolean => {
    return this.props.globals.styles.get(stylesParams.productPage_descriptionVisibleLineNumbers) > 2;
  };

  public isCollapsed(): boolean {
    return this.state.isCollapsedStatus && this.isShowMoreOrLess();
  }

  public toggleDescription(): void {
    const {isCollapsedStatus} = this.state;
    this.setState({isCollapsedStatus: !isCollapsedStatus});
  }

  public isNeedToCollapse(): boolean {
    return this.state.height > this.getCollapsedContentHeight();
  }

  private getCollapsedContentHeight(): number {
    const descriptionVisibleLineNumbers = this.props.globals.styles.get(
      stylesParams.productPage_descriptionVisibleLineNumbers
    );
    const {lineHeight} = this.state;
    return lineHeight * descriptionVisibleLineNumbers;
  }

  public getContentHeightStyle(): string {
    if (this.isCollapsed()) {
      return `${this.getCollapsedContentHeight()}px`;
    }
    return '';
  }

  private readonly getShowMoreOrLess = () => {
    const {t} = this.props;
    return (
      this.isShowMoreOrLess() &&
      this.isNeedToCollapse() && (
        <button
          type="button"
          aria-hidden="true"
          className={s.showMoreOrLess}
          onClick={() => this.toggleDescription()}
          data-hook={DataHook.ShowMoreOrLess}>
          {this.isCollapsed() ? t('DESCRIPTION_SHOW_MORE') : t('DESCRIPTION_SHOW_LESS')}
        </button>
      )
    );
  };

  public render(): JSX.Element {
    const {description} = this.props;
    const {lineHeight} = this.state;

    return !description ? null : (
      <section data-hook={DataHook.DescriptionWrapper} className={s.description}>
        <div className={s.descriptionWrapper}>
          <div
            className={s.contentWrapper}
            data-hook={DataHook.ContentWrapper}
            style={{maxHeight: this.getContentHeightStyle(), lineHeight: `${lineHeight}px`}}>
            <SanitizeInnerHTMLWrapper innerHtml={description}>
              <pre ref={this.ref} data-hook={DataHook.Description} className={s.content} />
            </SanitizeInnerHTMLWrapper>
          </div>
          {this.getShowMoreOrLess()}
        </div>
      </section>
    );
  }
}

export const ProductDescription = withGlobalProps(withTranslations('globals.texts')(ProductDescriptionComponent));
