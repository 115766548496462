import React from 'react';
import {ProductDescription} from '../../ProductDescription/ProductDescription';
import {InfoSectionLayout} from '../../InfoSectionLayout/InfoSectionLayout';
import {ProductName} from '../../ProductName/ProductName';
import {ProductSku} from '../../ProductSku/ProductSku';
import {Cell} from '../Cell/Cell';
import {LayoutComponentProps} from '../../../types/app-types';
import {ProductPageSlotIds} from '../../../constants';
import s from './SimpleLayout.scss';
import {RatingSummarySlot} from '../../RatingSummarySlot/RatingSummarySlot';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';

export const SimpleLayoutLeftSection: React.FunctionComponent<LayoutComponentProps> = ({settings, globals}) => {
  const {product} = globals;
  const {addReviewsSlotsToProductPage, renderProductPageSlots} = globals.experiments ?? {};

  return (
    <section className={s.leftSection}>
      <Cell>
        <ProductName name={product.name} />
      </Cell>
      {addReviewsSlotsToProductPage && <RatingSummarySlot />}
      {settings.shouldShowSku && (
        <Cell className={s.sku}>
          <ProductSku />
        </Cell>
      )}
      {renderProductPageSlots && (
        <Cell className={s.productPageSlot}>
          <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails2} />
        </Cell>
      )}
      <Cell className={s.description}>
        <ProductDescription description={product.description} />
      </Cell>
      {renderProductPageSlots && (
        <Cell className={s.productPageSlotMediumSpacing}>
          <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails4} />
        </Cell>
      )}
      {renderProductPageSlots && (
        <Cell className={s.productPageSlotBigSpacing}>
          <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails8} />
        </Cell>
      )}
      {settings.shouldShowInfoSection && (
        <Cell className={s.info}>
          <InfoSectionLayout />
        </Cell>
      )}
      {renderProductPageSlots && (
        <Cell>
          <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails9} />
        </Cell>
      )}
    </section>
  );
};
